/* eslint-disable react/no-danger */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { hideNotification } from './NotificationActions';

import './notification.scss';

const NotificationComponent = () => {
    const { show, color, message } = useSelector(state => state.notification);

    const dispatch = useDispatch();

    const display = show === true ? '' : ' d-none';

    React.useEffect(() => {
        let timer;
        if (color !== 'error') {
            timer = setTimeout(() => dispatch(hideNotification()), 5000);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [color, dispatch]);

    if (message) {
        return (
            <div className={`notification ${color}${display}`}>
                <span
                    className="close"
                    onClick={() => dispatch(hideNotification())}
                    role="presentation"
                >
                    &times;
                </span>
                <div>
                    <p dangerouslySetInnerHTML={{ __html: message }} />
                </div>
            </div>
        );
    }
    return <div />;
};

export default NotificationComponent;
