import React from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import * as detectActions from '@redux/detect/actions';
import Footer from '@app/layouts/footer/Index';
import getConfig from '@root/app/redux/initState/actions';
import Header from '@app/layouts/header/HeaderContainer';
import { addGlobalData } from '@app/layouts/header/HeaderActions';
import useFetch from '@components/Hooks/useFetch';

import { ORIENTATION } from '@components/constants';

import Body from '@pages/base/Body';

import '@pages/base/styles/Layout.scss';

const fetchConfig = {
    type: 'GET',
    url: '/get-data/home'
};

const Layout = () => {
    const dispatch = useDispatch();

    const [searchParams] = useSearchParams();

    // isWebView
    const isWebView = searchParams.get('isWebView') === '1';

    React.useEffect(() => {
        dispatch(
            addGlobalData({
                isWebView
            })
        );
    }, [dispatch, isWebView]);

    const { data } = useFetch(fetchConfig);

    React.useEffect(() => {
        dispatch(addGlobalData(data));
    }, [data, dispatch]);

    const setDetect = React.useCallback(
        ele => {
            dispatch(detectActions.setWidth(ele.innerWidth));
            if (!ele.navigator.maxTouchPoints) {
                dispatch(detectActions.setOrientation(ORIENTATION.DESKTOP));
                return;
            }

            dispatch(
                detectActions.setOrientation(
                    !ele.screen.orientation.angle ? ORIENTATION.PORTRAIT : ORIENTATION.LANDSCAPE
                )
            );
        },
        [dispatch]
    );
    const resizeHandler = React.useCallback(event => setDetect(event.currentTarget), [setDetect]);

    React.useEffect(() => {
        setDetect(window);

        window.addEventListener('resize', resizeHandler);

        return () => window.removeEventListener('resize', resizeHandler);
    }, [setDetect, resizeHandler]);

    React.useEffect(() => {
        getConfig(dispatch);
    }, [dispatch]);

    return (
        <div>
            <Header />
            <div className="main" role="main">
                <Body />
            </div>
            {!isWebView && <Footer />}
        </div>
    );
};

export default Layout;
