import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { defaultFailedResponse } from '@components/auth/fetchData';
import { showNotification } from '@components/Notification/NotificationActions';

const TYPES = {
    GET: 'GET',
    POST: 'POST'
};

const getFetchOptions = options => {
    let fetchOptions = {
        ...options
    };

    let { url } = options;

    let { headers } = options;

    switch (options.type) {
        case TYPES.POST:
            fetchOptions = {
                ...fetchOptions,
                method: 'POST',
                credentials: 'include',
                body: options.data
            };

            if (!options.hasFile) {
                headers = {
                    ...headers,
                    'Content-Type': 'application/json',
                    'X-Request-Fetch': 1,
                    'X-Requested-With': 'XMLHttpRequest'
                };

                fetchOptions.body = JSON.stringify(options.data);
            }
            break;

        case TYPES.GET:
        default:
            fetchOptions = {
                method: 'GET',
                ...fetchOptions
            };

            headers = {
                ...headers,
                'Content-Type': 'application/json',
                'X-Request-Fetch': 1,
                'X-Requested-With': 'XMLHttpRequest'
            };

            if (options.data && Object.keys(options.data).length) {
                url += '?';
                url += new URLSearchParams(options.data).toString();
            }
            break;
    }

    fetchOptions.headers = new Headers(headers);

    return {
        options: fetchOptions,
        url
    };
};

const droomFetch = conf => {
    const { url, options } = getFetchOptions(conf);

    return new Promise((resolve, reject) => {
        if (options.cacheRes) {
            const cachedResponse = sessionStorage.getItem(`apiFetch[${url}]`);
            if (cachedResponse) {
                resolve(JSON.parse(cachedResponse));
                return;
            }
        }

        fetch(url, options)
            .then(response =>
                response
                    .json()
                    .catch(
                        /* added in cases where response is not json and dot json call fails */ () =>
                            defaultFailedResponse
                    )
            )
            .then(res => {
                if (res?.code && res.code === 'failed') {
                    reject(res.errors || 'Something went wrong.');
                    return;
                }

                if (res.error_code && res.error_code === 401) {
                    window.location.replace(res.redirect);
                    resolve(null);
                    return;
                }

                let drmRes = res;

                if (res?.code && res.code === 'success') {
                    drmRes = res.data;
                }

                if (options.cacheRes) {
                    sessionStorage.setItem(
                        `apiFetch[${url}]`,
                        JSON.stringify(drmRes)
                    );
                }

                resolve(drmRes);
            })
            .catch(error => {
                reject(error);
            });
    });
};

const useFetch = conf => {
    const [fetchState, setFetchState] = React.useState({
        loading: true,
        data: null
    });

    const WEB_URL = useSelector(state => state.initState.WEB_URL);

    const dispatch = useDispatch();

    const fetchApi = React.useCallback(
        signal => {
            const droomFetchConf = {
                ...conf,
                url: `${WEB_URL}/${conf.url.replace(/^\//, '')}`,
                signal,
                processApiData: conf.processApiData
                    ? conf.processApiData
                    : res => res
            };

            if (!conf.url) {
                setFetchState({
                    loading: false,
                    data: null
                });

                return;
            }

            droomFetch(droomFetchConf)
                .then(res => {
                    const data = droomFetchConf.processApiData(res);

                    setFetchState({
                        loading: false,
                        data
                    });
                })
                .catch(err => dispatch(showNotification('error', err.message)));
        },
        [WEB_URL, conf, dispatch]
    );

    React.useEffect(() => {
        const newController = new AbortController();
        const { signal } = newController;

        fetchApi(signal);

        return () => newController.abort();
    }, [fetchApi]);

    return fetchState;
};

export default useFetch;
