import React from 'react';
import {
    DESKTOP_AD_ID1,
    MOBILE_AD_ID1
} from '@app/common/ObvGoogleAdsense/AdConstants';
import GoogleAd from '@app/common/ObvGoogleAdsense/GoogleAd';
import { DEVICE } from '@components/constants';
import { useSelector } from 'react-redux';

const HeaderAd = () => {
    const { device } = useSelector(state => state.detect);
    return device === DEVICE.MOBILE ? (
        <GoogleAd id={MOBILE_AD_ID1} />
    ) : (
        <GoogleAd id={DESKTOP_AD_ID1} />
    );
};

export default HeaderAd;
