import { useTranslator } from '@eo-locale/react';
import { getLocalizedPath } from '@config/index';

const useLocalizePath = path => {
    const translator = useTranslator();

    return getLocalizedPath(path, translator.language);
};

export default useLocalizePath;
