const encodeBase64 = str => {
    return window.btoa(str);
};
const decodeBase64 = str => {
    return window.atob(str);
};

export const setCookie = ({ name, value, time }) => {
    // time should be pass in days
    const d = new Date();
    d.setTime(d.getTime() + time * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${encodeBase64(name)}=${encodeBase64(
        value
    )};${expires};path=/`;
    return true;
};

export const getCookie = cookieName => {
    const name = `${encodeBase64(cookieName)}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i += 1) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return decodeBase64(c.substring(name.length, c.length));
        }
    }
    return '';
};
