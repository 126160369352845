import React from 'react';
import { useSelector } from 'react-redux';
import GoogleAd from '@app/common/ObvGoogleAdsense/GoogleAd';

const FooterAdWithClose = () => {
    const { device } = useSelector(state => state.detect);
    const { ADS_ACTIVE } = useSelector(state => state.initState);
    const [adOpen, setAdOpen] = React.useState(true);

    if (!adOpen) {
        return null;
    }

    if (!ADS_ACTIVE) {
        return null;
    }

    return (
        <div className="google-ad-el">
            <div className="inner-el">
                <button type="button" onClick={() => setAdOpen(false)}>
                    X
                </button>
                {device === 'mobile' ? (
                    <GoogleAd id="div-gpt-ad-1699448766569-0" />
                ) : (
                    <GoogleAd id="div-gpt-ad-1699448685679-0" />
                )}
            </div>
        </div>
    );
};

export default FooterAdWithClose;
