import React from 'react';
import { Outlet } from 'react-router-dom';
import ErrorBoundary from '@components/ErrorBoundary';
import ToTop from '@components/ToTop/ToTop';
import Notification from '@components/Notification/NotificationContainer';

import './styles/Body.scss';

const Body = () => {
    return (
        <ErrorBoundary>
            <ToTop />
            <Notification />
            <Outlet />
        </ErrorBoundary>
    );
};

export default Body;
