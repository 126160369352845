import {
    LOGIN_COMPONENT,
    REGISTER_COMPONENT,
    U_PROFILE_COMPONENT,
    PROFILE_COMPONENT,
    PAYMENT_COMPONENT,
    OTP_COMPONENT,
    FORGOT_PASSWORD_COMPONENT,
    SET_PASSWORD_COMPONENT,
    SET_PROFILE_DATA
} from './CheckoutActionTypes';

export function login() {
    return { type: LOGIN_COMPONENT };
}

export function register() {
    return { type: REGISTER_COMPONENT };
}

export function uProfile(profileData) {
    return { type: U_PROFILE_COMPONENT, profileData };
}

export function profile(profileData) {
    return { type: PROFILE_COMPONENT, profileData };
}

export function payment() {
    return { type: PAYMENT_COMPONENT };
}

export function otp() {
    return { type: OTP_COMPONENT };
}

export function forgotPassword() {
    return { type: FORGOT_PASSWORD_COMPONENT };
}

export function setPassword(email) {
    return { type: SET_PASSWORD_COMPONENT, email };
}

export function setProfileData(profileData) {
    return { type: SET_PROFILE_DATA, profileData };
}
