import React, {Component} from 'react'

class TextChange extends Component {
	constructor(props) {
		super(props);

		this.handleClick = this.handleClick.bind(this);

		this.state = {
			value: this.props.first,
			clicked: 0
		}
	}

	handleClick() {
		if (!this.state.clicked) {
			this.setState({
				clicked: 1,
				value: this.props.second
			});
		}
		else {
			this.setState({
				clicked: 0,
				value: this.props.first
			});
		}

	}

	render() {
		return (<div onClick={this.handleClick}>
			{this.state.value}
		</div>);
	}
}

export default TextChange
