import React from 'react';
import { useSelector } from 'react-redux';

import useLocalizePath from '@components/Hooks/useLocalizePath';
import { Link } from 'react-router-dom';

import './logo.scss';

const Logo = () => {
    const isWebView = useSelector(state => state.header.globalData.isWebView);

    const homePagePath = useLocalizePath('/');

    return (
        <div className="logo" role="main">
            {isWebView ? (
                <figure alt="Orangebookvalue.com - Used Vehicle Pricing Calculator" />
            ) : (
                <Link to={homePagePath}>
                    <figure alt="Orangebookvalue.com - Used Vehicle Pricing Calculator" />
                </Link>
            )}
        </div>
    );
};

export default Logo;
