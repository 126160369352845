import React from 'react';
import PropTypes from 'prop-types';
import lazy from '@loadable/component';

import Loader from '@components/Loader';

const loadable = loadFunc => {
    return lazy(loadFunc, {
        fallback: <Loader />
    });
};

const ModalContainer = ({ name, heading, component, size, ...rest }) => {
    const Component = React.useMemo(() => loadable(component), [component]);

    return (
        <div id={name} className="modal" tabIndex="-1" role="dialog">
            <div className={`modal-dialog ${size}`} role="document">
                <Component
                    ownProps={{ name, heading, component, size, ...rest }}
                />
            </div>
        </div>
    );
};

ModalContainer.propTypes = {
    name: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
    component: PropTypes.shape({
        chunkName: PropTypes.func.isRequired,
        isReady: PropTypes.func.isRequired,
        requireAsync: PropTypes.func.isRequired,
        requireSync: PropTypes.func.isRequired,
        resolve: PropTypes.func.isRequired
    }).isRequired,
    size: PropTypes.string
};

ModalContainer.defaultProps = {
    size: ''
};

export default ModalContainer;
