import React from 'react';
import TextChange from '@components/elements/TextChange';
import { Text } from '@eo-locale/react';
import FooterAd from '@app/layouts/footer/FooterAd';
import FooterAdWithClose from './FooterAdWithClose';
import './footer.scss';

// The Header creates links that can be used to navigate
// between routes.
const Footer = () => {
    return (
        <footer role="main">
            <FooterAd />
            <FooterAdWithClose />
            <div className="d-lg-block links">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="block">
                                <div className="content">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-3">
                                            <div className="header">
                                                <h6 className="heading">
                                                    {' '}
                                                    <Text id="About_OBV" defaultMessage="About OBV" />
                                                </h6>
                                            </div>
                                            <ul>
                                                <li>
                                                    <a href="https://orangebookvalue.com/#what">
                                                        {' '}
                                                        <Text id="What_is_OBV" defaultMessage="What is OBV" />?
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://orangebookvalue.com/determine-condition">
                                                        {' '}
                                                        {
                                                            <Text
                                                                id="How_to_Determine_Condition"
                                                                defaultMessage="How to Determine Condition"
                                                            />
                                                        }{' '}
                                                        ?
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://orangebookvalue.com/premium-reports">
                                                        {' '}
                                                        <Text id="Premium_Report" defaultMessage="Valuation Report" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://orangebookvalue.com/methodology">
                                                        {' '}
                                                        <Text id="Methodology" defaultMessage="Methodology" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://orangebookvalue.com/glossary">
                                                        {' '}
                                                        <Text id="Glossary" defaultMessage="Glossary" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-xs-12 col-sm-3">
                                            <div className="header">
                                                <h6 className="heading">
                                                    {' '}
                                                    {<Text id="Offerings" defaultMessage="Offerings" />}{' '}
                                                </h6>
                                            </div>
                                            <ul>
                                                <li>
                                                    <a href="https://orangebookvalue.com/print-edition">
                                                        {' '}
                                                        {
                                                            <Text id="Print_Edition" defaultMessage="Print Edition" />
                                                        }{' '}
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://orangebookvalue.com/enterprise">
                                                        {' '}
                                                        <Text id="OBV_Enterprise" defaultMessage="OBV Enterprise" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://orangebookvalue.com/used-vehicle">
                                                        {' '}
                                                        {
                                                            <Text
                                                                id="Used_Vehicle_Price"
                                                                defaultMessage="Used Vehicle Price"
                                                            />
                                                        }{' '}
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://orangebookvalue.com/used-cars">
                                                        {' '}
                                                        <Text id="Used_Car_Price" defaultMessage="Used Car Price" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://orangebookvalue.com/used-bikes">
                                                        {' '}
                                                        <Text id="Used_Bike_Price" defaultMessage="Used Bike Price" />
                                                    </a>
                                                </li>
                                                <div id="offeringMore" className="collapse">
                                                    <li>
                                                        <a href="https://orangebookvalue.com/used-scooters">
                                                            {
                                                                <Text
                                                                    id="Used_Scooter_Price"
                                                                    defaultMessage="Used Scooter Price"
                                                                />
                                                            }{' '}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="https://orangebookvalue.com/new-cars">
                                                            {' '}
                                                            {
                                                                <Text
                                                                    id="New_Car_Price"
                                                                    defaultMessage="New Car Price"
                                                                />
                                                            }{' '}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="https://orangebookvalue.com/new-bikes">
                                                            {' '}
                                                            <Text id="New_Bike_Price" defaultMessage="New_Bike_Price" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="https://orangebookvalue.com/new-scooters">
                                                            {' '}
                                                            <Text
                                                                id="New_Scooter_Price"
                                                                defaultMessage="New Scooter Price"
                                                            />
                                                        </a>
                                                    </li>
                                                </div>
                                                <a
                                                    data-toggle="collapse"
                                                    href="#offeringMore"
                                                    role="button"
                                                    aria-expanded="false"
                                                    aria-controls="offeringMore"
                                                    className="obv-display-inline-block"
                                                >
                                                    <TextChange
                                                        first={<Text id="View_More[+]" defaultMessage="View_More[+]" />}
                                                        second={
                                                            <Text id="View_Less[-]" defaultMessage="View Less[-]" />
                                                        }
                                                    />
                                                </a>
                                            </ul>
                                        </div>
                                        <div className="col-xs-12 col-sm-3">
                                            <div className="header">
                                                <h6 className="heading">
                                                    {' '}
                                                    {<Text id="Media" defaultMessage="Media" />}{' '}
                                                </h6>
                                            </div>
                                            <ul>
                                                <li>
                                                    <a href="https://orangebookvalue.com/press-kit">
                                                        {' '}
                                                        {
                                                            <Text
                                                                id="Brand_Guidelines"
                                                                defaultMessage="Brand Guidelines"
                                                            />
                                                        }{' '}
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://orangebookvalue.com/blog">
                                                        {' '}
                                                        <Text id="Blog" defaultMessage="Blog" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://orangebookvalue.com/press">
                                                        {' '}
                                                        <Text id="In_The_Press" defaultMessage="In The Press" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://orangebookvalue.com/obv-colada">
                                                        {<Text id="OBV_Colada" defaultMessage="OBV Colada" />}{' '}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="col-xs-12 col-sm-3">
                                            <div className="header">
                                                <h6 className="heading">
                                                    {' '}
                                                    {
                                                        <Text id="Let_Us_Help_You" defaultMessage="Let Us Help You" />
                                                    }{' '}
                                                </h6>
                                            </div>
                                            <ul>
                                                <li id="footer-contact-us">
                                                    <a href="#footer-contact-us" role="button">
                                                        {' '}
                                                        <Text id="Contact_Us" defaultMessage="Contact Us" /> (9650
                                                        080808)
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="mailto:support@orangebookvalue.com">
                                                        {' '}
                                                        {<Text id="Support" defaultMessage="Support" />}{' '}
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://orangebookvalue.com/raise-concern">
                                                        {' '}
                                                        {
                                                            <Text id="Raise_Concern" defaultMessage="Raise Concern" />
                                                        }{' '}
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://orangebookvalue.com/faqs">
                                                        {' '}
                                                        <Text id="FAQs" defaultMessage="FAQs" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="block">
                                <div className="content">
                                    <div className="header">
                                        <h6 className="heading">
                                            {' '}
                                            <Text id="Also_Available_In" defaultMessage="Also Available In" />
                                        </h6>
                                    </div>
                                    <div className="footer_nav_new">
                                        <div className="row">
                                            <div className="col-md-1 col-sm-2">
                                                <label>
                                                    {' '}
                                                    <Text id="Asia" defaultMessage="Asia" />:
                                                </label>
                                            </div>
                                            <div className="col-md-11 col-sm-10">
                                                <ul>
                                                    <li>
                                                        <a href="https://orangebookvalue.com/">
                                                            {' '}
                                                            <Text id="India" defaultMessage="India" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="https://orangebookvalue.com/sg/">
                                                            {' '}
                                                            {<Text id="Singapore" defaultMessage="Singapore" />}{' '}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="https://orangebookvalue.com/th/">
                                                            {' '}
                                                            <Text id="Thailand" defaultMessage="Thailand" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="https://orangebookvalue.com/my/">
                                                            {' '}
                                                            <Text id="Malaysia" defaultMessage="Malaysia" />
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-1 col-sm-2">
                                                <label>
                                                    {' '}
                                                    <Text id="Middle_East" defaultMessage="Middle East" /> :
                                                </label>
                                            </div>
                                            <div className="col-md-11 col-sm-10">
                                                <ul>
                                                    <li>
                                                        <a href="https://orangebookvalue.com/global-uae">
                                                            {' '}
                                                            {<Text id="UAE" defaultMessage="UAE" />}{' '}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="https://orangebookvalue.com/global-sa">
                                                            {' '}
                                                            <Text id="Saudi_Arabia" defaultMessage="Saudi Arabia" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="https://orangebookvalue.com/global-kw">
                                                            {' '}
                                                            <Text id="Kuwait" defaultMessage="Kuwait" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="https://orangebookvalue.com/global-om">
                                                            {' '}
                                                            <Text id="Oman" defaultMessage="Oman" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="https://orangebookvalue.com/global-qa">
                                                            {' '}
                                                            <Text id="Qatar" defaultMessage="Qatar" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="https://orangebookvalue.com/global-bh">
                                                            {' '}
                                                            <Text id="Bahrain" defaultMessage="Bahrain" />
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-1 col-sm-2">
                                                <label htmlFor="Europe">
                                                    <Text id="Europe" defaultMessage="Europe" /> :
                                                </label>
                                            </div>
                                            <div className="col-md-11 col-sm-10">
                                                <ul>
                                                    <li>
                                                        <a
                                                            href="https://orangebookvalue.com/at/de"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {' '}
                                                            {<Text id="Austria" defaultMessage="Austria" />}{' '}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="https://orangebookvalue.com/be/en"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {<Text id="Belgium" defaultMessage="Belgium" />}{' '}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="https://orangebookvalue.com/bg/en"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {<Text id="Bulgaria" defaultMessage="Bulgaria" />}{' '}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="https://orangebookvalue.com/hr/en"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {<Text id="Croatia" defaultMessage="Croatia" />}{' '}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="https://orangebookvalue.com/cy/en"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {<Text id="Cyprus" defaultMessage="Cyprus" />}{' '}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="https://orangebookvalue.com/cz/en"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            <Text id="Czech_Republic" defaultMessage="Czech Republic" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="https://orangebookvalue.com/dk/en"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {' '}
                                                            {<Text id="Denmark" defaultMessage="Denmark" />}{' '}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="https://orangebookvalue.com/ee/en"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {' '}
                                                            {<Text id="Estonia" defaultMessage="Estonia" />}{' '}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="https://orangebookvalue.com/fi/en"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {' '}
                                                            {<Text id="Finland" defaultMessage="Finland" />}{' '}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="https://orangebookvalue.com/fr/fr"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {' '}
                                                            {<Text id="France" defaultMessage="France" />}{' '}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="https://orangebookvalue.com/de/de"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {' '}
                                                            {<Text id="Germany" defaultMessage="Germany" />}{' '}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="https://orangebookvalue.com/gr/en"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {' '}
                                                            {<Text id="Greece" defaultMessage="Greece" />}{' '}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="https://orangebookvalue.com/hu/en"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {' '}
                                                            {<Text id="Hungary" defaultMessage="Hungary" />}{' '}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="https://orangebookvalue.com/ie/en"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {' '}
                                                            {<Text id="Ireland" defaultMessage="Ireland" />}{' '}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="https://orangebookvalue.com/it/it"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {' '}
                                                            {<Text id="Italy" defaultMessage="Italy" />}{' '}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="https://orangebookvalue.com/lv/en"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {' '}
                                                            {<Text id="Latvia" defaultMessage="Latvia" />}{' '}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="https://orangebookvalue.com/lt/en"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {' '}
                                                            {<Text id="Lithuania" defaultMessage="Lithuania" />}{' '}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="https://orangebookvalue.com/lu/en"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {' '}
                                                            {<Text id="Luxembourg" defaultMessage="Luxembourg" />}{' '}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="https://orangebookvalue.com/mt/en"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {' '}
                                                            {<Text id="Malta" defaultMessage="Malta" />}{' '}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="https://orangebookvalue.com/nl/en"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {' '}
                                                            {
                                                                <Text id="Netherlands" defaultMessage="Netherlands" />
                                                            }{' '}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="https://orangebookvalue.com/pl/en"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {' '}
                                                            {<Text id="Poland" defaultMessage="Poland" />}{' '}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="https://orangebookvalue.com/pt/en"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {' '}
                                                            {<Text id="Portugal" defaultMessage="Portugal" />}{' '}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="https://orangebookvalue.com/ro/en"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {' '}
                                                            {<Text id="Romania" defaultMessage="Romania" />}{' '}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="https://orangebookvalue.com/sk/en"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {' '}
                                                            {<Text id="Slovakia" defaultMessage="Slovakia" />}{' '}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="https://orangebookvalue.com/si/en"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {' '}
                                                            {<Text id="Slovenia" defaultMessage="Slovenia" />}{' '}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="https://orangebookvalue.com/es/es"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {' '}
                                                            {<Text id="Spain" defaultMessage="Spain" />}{' '}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="https://orangebookvalue.com/se/en"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {' '}
                                                            {<Text id="Sweden" defaultMessage="Sweden" />}{' '}
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-1 col-sm-2">
                                                <label>
                                                    {' '}
                                                    <Text id="ANZ" defaultMessage="ANZ" /> :
                                                </label>
                                            </div>
                                            <div className="col-md-11 col-sm-10">
                                                <ul>
                                                    <li>
                                                        <a
                                                            href="https://orangebookvalue.com/au/en"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {' '}
                                                            <Text id="Australia" defaultMessage="Australia" />
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 text-right">
                            <div className="block">
                                <div className="content footer">
                                    <a href="https://droom.in" target="_blank" rel="noreferrer">
                                        <figure className="sprite service" />
                                    </a>
                                </div>

                                <div className="social mb-3">
                                    <a href="/social" target="_blank">
                                        <i className="icon icon-facebook" />
                                    </a>
                                    <a href="/social" target="_blank">
                                        <i className="icon icon-twitter" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 hidden-xs text-center">
                            <div className="footer_nav_new">
                                <ul>
                                    <li id="footer-india">
                                        <a href="#footer-india" role="button">
                                            {' '}
                                            {<Text id="India" defaultMessage="India" />}{' '}
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://orangebookvalue.com/my/">
                                            {' '}
                                            {<Text id="Malaysia" defaultMessage="Malaysia" />}{' '}
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://orangebookvalue.com/sg/">
                                            {' '}
                                            {<Text id="Singapore" defaultMessage="Singapore" />}{' '}
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://orangebookvalue.com/th/">
                                            {<Text id="Thailand" defaultMessage="Thailand" />}{' '}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container faqs d-none d-lg-block">
                <h6>What is OBV</h6>
                <p>
                    Orange Book Value is a platform that helps in determining the fair market price of used vehicles. It
                    is basically an algorithmic pricing engine that suggests market value of used cars, used bikes and
                    used scooters. Questions like what is the value of my used car or used bike come to our mind when we
                    decide to sell it off. However, we do not get to understand the proper valuation of our vehicles.
                    <br />
                    <br />
                    In order to come up with a proper used car valuation or used bike valuation, Orange Book Value uses
                    real time data science to value depreciation of automobiles. It helps the sellers put a fair price
                    in front of the buyer, and for the buyers, it helps them get a product that serves great value for
                    their money. This is a service by droom, which was founded by{' '}
                    <a href="http://sandeepaggarwal.com" target="_blank" rel="noreferrer">
                        Sandeep Aggarwal
                    </a>
                    .
                </p>

                <h6>Why OBV?</h6>
                <p>
                    Orange Book Value works as an independent value calculator for cars, bikes and scooters that
                    determines second-hand vehicle prices. It uses Droom&lsquo;s proprietary technology and data science
                    to come up with fair market price of used cars, bikes and scooters, and utilises scientific data to
                    get the exact value for the vehicle. The primary objective of this used-vehicle price guide is to
                    get the fair market price for second-hand vehicles.
                    <br />
                    <br />
                    Another reason for using Orange Book Value is its unbiased results that help in obtaining reliable
                    data on used-vehicle pricing. Also, it is the kind of valuation tool for used scooters, bikes and
                    cars that calculates the data on real-time basis with a scientific method of suggesting their
                    current market value.
                </p>

                <h6>How Does it Work?</h6>
                <p>
                    To get the fair market price of your used vehicle, you need to follow just four simple steps. At
                    first, you need to select the purpose, that means whether you want to buy or sell the vehicle. After
                    that you need to select the category of used vehicle, which is whether you have a used car, bike or
                    scooter. The next step is selecting the parameters such as make, model, year, trim, etc., of the
                    product for which you need to get the correct market valuation. The market value of your used bike,
                    car or scooter will highly depend on the condition of the vehicle and the kms driven. After that,
                    you will get the exact valuation of your vehicle through Orange Book Value.
                </p>

                <h6>Benefits of OBV</h6>
                <p>
                    Orange Book Value helps buyers to understand the fair value of a used car, bike or scooter. This
                    way, the buyer will not have to guess the price of a used car or bike and can be satisfied with
                    price that he/she is paying for the product. For sellers, it helps in determining the best market
                    value for their used vehicle. This valuation tool for used cars and bikes creates a transparency in
                    the overall process. This is a pricing guide for used cars, used bikes and used scooters that helps
                    in quick selling of second-hand vehicles. For banks and NBFCs, it helps in getting the fair pricing
                    of used vehicles that further enhances the process of loan approvals. For insurance companies as
                    well, the real-time data obtained through scientific calculation helps in obtaining the current
                    valuation of the second-hand cars, bikes and scooters.
                </p>

                <h6>OBV Methodology</h6>
                <p>
                    Being the most advanced algorithmic and data science-based value calculator, Orange Book Value is
                    the most accurate valuation tool for used cars, bike and scooters in India. Unlike other pricing
                    engines, Orange Book Value utilises Droom&lsquo;s proprietary data science methodologies and latest
                    technologies to get the correct pricing of used cars, bikes and scooters depending upon various
                    factors that lead to value depreciation such as make, model, trim, data for kms driven that is
                    obtained through odometer reading, current condition of the vehicle that includes wear and tear,
                    dents, major repair works, etc. A vehicle that is well-maintained and kept in good shape gets to
                    score more in the OBV methodology and gets a better market value.
                </p>

                <h6>Appropriate Condition</h6>
                <p>
                    Orange Book Value calculates the valuation of a used car, bike or scooter according to different
                    parameters that cause value depreciation of second-hand vehicles. When pricing engine comes up with
                    a particular result based on the make, year of manufacture, trim of the vehicle, odometer reading
                    that decides the total number of kms driven and the physical and mechanical condition of the
                    vehicle, the used cars and bikes are rated on its basis. These ratings include Excellent, Very Good,
                    Good and Fair. While Excellent and Very Good signify a healthy used car, Good and Fair indicate that
                    it has problem with some of the parts and needs special attention and repairing.
                </p>
            </div>

            <div className="container  d-block d-lg-none">
                <div className="row">
                    <div className="col" style={{ padding: '10px' }}>
                        <h6>What is OBV</h6>
                        <p>
                            Orange Book Value is a platform that helps in determining the fair market price of used
                            vehicles. It is basically an algorithmic pricing engine that suggests market value of used
                            cars, used bikes and used scooters. Questions like what is the value of my used car or used
                            bike come to our mind when we decide to sell it off. However, we do not get to understand
                            the proper valuation of our vehicles.
                        </p>
                        <div id="collapseWhat" className="collapse">
                            <p>
                                In order to come up with a proper used car valuation or used bike valuation, Orange Book
                                Value uses real time data science to value depreciation of automobiles. It helps the
                                sellers put a fair price in front of the buyer, and for the buyers, it helps them get a
                                product that serves great value for their money. This is a service by droom, which was
                                founded by{' '}
                                <a href="http://sandeepaggarwal.com" target="_blank" rel="noreferrer">
                                    Sandeep Aggarwal
                                </a>
                                .
                            </p>
                            <h6>Why OBV?</h6>
                            <p>
                                Orange Book Value works as an independent value calculator for cars, bikes and scooters
                                that determines second-hand vehicle prices. It uses Droom&lsquo;s proprietary technology
                                and data science to come up with fair market price of used cars, bikes and scooters, and
                                utilises scientific data to get the exact value for the vehicle. The primary objective
                                of this used-vehicle price guide is to get the fair market price for second-hand
                                vehicles.
                                <br />
                                <br />
                                Another reason for using Orange Book Value is its unbiased results that help in
                                obtaining reliable data on used-vehicle pricing. Also, it is the kind of valuation tool
                                for used scooters, bikes and cars that calculates the data on real-time basis with a
                                scientific method of suggesting their current market value.
                            </p>

                            <h6>How Does it Work?</h6>
                            <p>
                                To get the fair market price of your used vehicle, you need to follow just four simple
                                steps. At first, you need to select the purpose, that means whether you want to buy or
                                sell the vehicle. After that you need to select the category of used vehicle, which is
                                whether you have a used car, bike or scooter. The next step is selecting the parameters
                                such as make, model, year, trim, etc., of the product for which you need to get the
                                correct market valuation. The market value of your used bike, car or scooter will highly
                                depend on the condition of the vehicle and the kms driven. After that, you will get the
                                exact valuation of your vehicle through Orange Book Value.
                            </p>

                            <h6>Benefits of OBV</h6>
                            <p>
                                Orange Book Value helps buyers to understand the fair value of a used car, bike or
                                scooter. This way, the buyer will not have to guess the price of a used car or bike and
                                can be satisfied with price that he/she is paying for the product. For sellers, it helps
                                in determining the best market value for their used vehicle. This valuation tool for
                                used cars and bikes creates a transparency in the overall process. This is a pricing
                                guide for used cars, used bikes and used scooters that helps in quick selling of
                                second-hand vehicles. For banks and NBFCs, it helps in getting the fair pricing of used
                                vehicles that further enhances the process of loan approvals. For insurance companies as
                                well, the real-time data obtained through scientific calculation helps in obtaining the
                                current valuation of the second-hand cars, bikes and scooters.
                            </p>

                            <h6>OBV Methodology</h6>
                            <p>
                                Being the most advanced algorithmic and data science-based value calculator, Orange Book
                                Value is the most accurate valuation tool for used cars, bike and scooters in India.
                                Unlike other pricing engines, Orange Book Value utilises Droom&lsquo;s proprietary data
                                science methodologies and latest technologies to get the correct pricing of used cars,
                                bikes and scooters depending upon various factors that lead to value depreciation such
                                as make, model, trim, data for kms driven that is obtained through odometer reading,
                                current condition of the vehicle that includes wear and tear, dents, major repair works,
                                etc. A vehicle that is well-maintained and kept in good shape gets to score more in the
                                OBV methodology and gets a better market value.
                            </p>

                            <h6>Appropriate Condition</h6>
                            <p>
                                Orange Book Value calculates the valuation of a used car, bike or scooter according to
                                different parameters that cause value depreciation of second-hand vehicles. When pricing
                                engine comes up with a particular result based on the make, year of manufacture, trim of
                                the vehicle, odometer reading that decides the total number of kms driven and the
                                physical and mechanical condition of the vehicle, the used cars and bikes are rated on
                                its basis. These ratings include Excellent, Very Good, Good and Fair. While Excellent
                                and Very Good signify a healthy used car, Good and Fair indicate that it has problem
                                with some of the parts and needs special attention and repairing.
                            </p>
                        </div>
                        <a
                            data-toggle="collapse"
                            href="#collapseWhat"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                            className="obv-display-inline-block"
                        >
                            <TextChange first="Read More" second="Collapse" />
                        </a>
                    </div>
                </div>
            </div>

            <div className="copyright">
                <Text
                    id="Copyright_OrangeBookValue_All_Rights_Reserved"
                    currentYear={new Date().getFullYear()}
                    defaultMessage={`Copyright © ${new Date().getFullYear()} OrangeBookValue All Rights Reserved.`}
                />
                <div className="more-links">
                    <a href="https://orangebookvalue.com/privacy-policy">
                        {' '}
                        {<Text id="Privacy_Policy" defaultMessage="Privacy Policy" />}{' '}
                    </a>
                    <a href="https://orangebookvalue.com/terms-of-use">
                        {' '}
                        {<Text id="Terms_of_Use" defaultMessage="Terms Of Use" />}{' '}
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
