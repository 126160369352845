import React from 'react';
import useCurrentRoute from '@components/Hooks/useCurrentRoute';
import { useSelector } from 'react-redux';
import {
    DESKTOP_AD_ID3,
    MOBILE_AD_ID3
} from '@app/common/ObvGoogleAdsense/AdConstants';
import GoogleAd from '@app/common/ObvGoogleAdsense/GoogleAd';
import { DEVICE } from '@components/constants';

const FooterAd = () => {
    const { device } = useSelector(state => state.detect);
    const { route } = useCurrentRoute();
    return (
        !route?.hidefooterAd &&
        (device === DEVICE.MOBILE ? (
            <GoogleAd id={MOBILE_AD_ID3} />
        ) : (
            <GoogleAd id={DESKTOP_AD_ID3} />
        ))
    );
};

export default FooterAd;
