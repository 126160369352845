import { nodeFetch } from '@components/Utilities';
import TYPES from './types';

const getConfig = dispatch => {
    nodeFetch({
        path: '/react-config',
        method: 'GET'
    }).then(data => {
        dispatch({
            type: TYPES.GET_CONFIG,
            payload: {
                SALE_ON: data?.sale_on || false,
                ADS_ACTIVE: data?.ads_active || false
            }
        });
    });
};

export default getConfig;
