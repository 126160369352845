import { SET_DEVICE, SET_WIDTH, SET_ORIENTATION } from './types';

export const setDevice = device => ({
    type: SET_DEVICE,
    payload: device
});

export const setWidth = width => ({
    type: SET_WIDTH,
    payload: width
});

export const setOrientation = orientation => ({
    type: SET_ORIENTATION,
    payload: orientation
});
