import { useLocation, matchRoutes } from 'react-router-dom';
import { routes } from '@app/routes';

const useCurrentRoute = () => {
    const location = useLocation();
    const matches = matchRoutes(routes, location).slice(-1).pop();

    return matches;
};

export default useCurrentRoute;
