import React from 'react';
import HeaderAd from '@app/layouts/header/HeaderAd';
import HeaderComponent from './HeaderComponent';

const Header = () => {
    return (
        <>
            <HeaderComponent />
            <HeaderAd />
        </>
    );
};

export default Header;
