import React from 'react';
import PropTypes from 'prop-types';
import { env } from '../../utils';

export default class ErrorBoundary extends React.Component {
    constructor() {
        super();
        this.state = {
            error: null,
            errorInfo: null
        };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ error, errorInfo });
    }

    render() {
        const { error, errorInfo } = this.state;

        const { children } = this.props;

        if (!errorInfo) {
            return children;
        }

        return (
            <>
                <h3>Sorry! An error occured while rendering page.</h3>
                {env.isDev && (
                    <pre>
                        {error && error.toString()}
                        <br />
                        {errorInfo.componentStack}
                    </pre>
                )}
            </>
        );
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.arrayOf(PropTypes.element)
};

ErrorBoundary.defaultProps = {
    children: null
};
