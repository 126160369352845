import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '@eo-locale/react';
import ShowModalAction from '@components/Modal/ModalActions';

import Login from './Login';

const User = () => {
    const dispatch = useDispatch();

    const cityClickHandler = () => {
        dispatch(ShowModalAction('locationModal'));
    };

    const location = useSelector(state => state.header.selectedLocation);

    React.useEffect(() => {
        document.getElementById('usedCity') &&
            (document.getElementById('usedCity').value = location);
        document.getElementById('getSelectedLocation') &&
            (document.getElementById('getSelectedLocation').textContent =
                location);
    }, [location]);

    return (
        <ul className="navbar-nav ml-auto">
            <Login />

            <li className="nav-item">
                <a
                    href="#locationModal"
                    type="button"
                    className="nav-link"
                    data-toggle="modal"
                    data-target="#locationModal"
                    onClick={cityClickHandler}
                >
                    <i className="obv obv-marker" />
                    {location || <Text id="City" defaultMessage="City" />}
                </a>
            </li>

            <a
                className="d-none d-lg-block ml-2"
                href="https://itunes.apple.com/us/app/orange-book-value/id1120532296?mt=8"
                target="_blank"
                rel="noreferrer"
            >
                <i className="icon icon-apple" />
            </a>
            <a
                className="d-none d-lg-block ml-2"
                href="https://play.google.com/store/apps/details?id=in.droom.online_obv_app"
                target="_blank"
                rel="noreferrer"
            >
                <i className="icon icon-google-store" />
            </a>
        </ul>
    );
};

export default User;
