import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Enterprise = () => {
    const profileData = useSelector(state => state.checkout.profileData);

    const Logout = React.useCallback(event => {
        event.preventDefault();
        sessionStorage.clear();
        window.user_details = undefined;

        window.location.assign('/account/logout');
    }, []);

    const userName = React.useMemo(() => {
        return profileData?.first_name
            ? `${profileData.first_name} ${profileData.last_name}`
            : 'User';
    }, [profileData]);

    return (
        <>
            <div className="">
                <strong className="obv-display-block">Hello {userName}</strong>
                How are you today?
            </div>
            <ul id="navbarDropdownEp" className="navbar-nav ml-auto">
                <li className="nav-item dropdown show">
                    <a
                        role="button"
                        id="navbarDropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        href="#navbarDropdownEp"
                        data-toggle="dropdown"
                        className="nav-link dropdown-toggle"
                    >
                        <i className="icon icon-buyer" />
                    </a>
                    <div
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                    >
                        <Link
                            onClick={Logout}
                            to="/enterprise-login"
                            className="nav-link"
                        >
                            Logout
                        </Link>
                    </div>
                </li>
            </ul>
        </>
    );
};

export default Enterprise;
