import React from 'react';

import './Top.scss';

const ToTop = () => {
    const [show, setShow] = React.useState(false);

    const checkWindowScroll = React.useCallback(() => {
        const { scrollY } = window;
        if (scrollY > 500) {
            setShow(true);
        } else {
            setShow(false);
        }
    }, []);

    React.useEffect(() => {
        checkWindowScroll();
        window.addEventListener('scroll', checkWindowScroll);

        return () => window.removeEventListener('scroll', checkWindowScroll);
    }, [checkWindowScroll]);

    const handleClick = () => {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
    };

    if (!show) return null;

    return (
        <div
            className="to-top"
            onClick={handleClick}
            onKeyUp={handleClick}
            role="button"
            tabIndex={0}
        >
            <i className="icon">^</i>
            <br />
            <small>Go Up</small>
        </div>
    );
};

export default ToTop;
