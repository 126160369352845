import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslator } from '@eo-locale/react';
import { useSelector } from 'react-redux';

import { firstLetterUpperCase } from '@components/Utilities';

const Login = () => {
    const [localState, setLocalState] = React.useState({
        status: undefined,
        navLogin: [
            {
                text: 'User_Login',
                href: '/login',
                type: 'a'
            },
            {
                text: 'Enterprise_Login',
                href: '/enterprise-login',
                type: 'link'
            }
        ],
        navLogged: []
    });

    const translator = useTranslator();

    const profileDetails = useSelector(state => state.checkout.profileData);

    const getNavLoggedDynamic = details => {
        const navLogged = {
            text: 'My Account',
            href: '/account/info',
            type: 'a'
        };
        if (details) {
            if (details.account_type === 'enterprise') {
                navLogged.href = '/enterprise/dealer-dashboard';
                navLogged.text = 'Dashboard';
                navLogged.type = 'link';
                if (details.isDash) {
                    navLogged.href = '/enterprise/dashboard';
                }
            }
        }

        return navLogged;
    };

    const updateUserHeaderSection = React.useCallback((value, details) => {
        setLocalState({
            status: value,
            navLogged: [
                {
                    text: `Welcome ${value}`,
                    href: '',
                    disabled: 'disabled'
                },
                getNavLoggedDynamic(details),
                {
                    text: 'Logout',
                    href: '/account/logout',
                    type: 'a',
                    onClick: () => sessionStorage.clear()
                }
            ]
        });
    }, []);

    React.useEffect(() => {
        if (!profileDetails?.account_type) return;
        const value = profileDetails.first_name
            ? profileDetails.first_name
            : profileDetails.account_type;

        updateUserHeaderSection(firstLetterUpperCase(value), profileDetails);
    }, [updateUserHeaderSection, profileDetails]);

    return (
        <li className="nav-item dropdown" id="header-login-nav">
            <a
                className="nav-link dropdown-toggle"
                href="#header-login-nav"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
                {localState.status
                    ? `Welcome ${localState.status}`
                    : translator.translate('Login')}
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                {localState.status
                    ? localState.navLogged.map((value, i) =>
                          value.type === 'link' ? (
                              <Link
                                  className={
                                      i === 0
                                          ? 'dropdown-item no-active'
                                          : 'dropdown-item'
                                  }
                                  key={`${value.text}-nav-key`}
                                  to={value.href}
                              >
                                  {value.text}
                              </Link>
                          ) : (
                              <a
                                  onClick={value.onClick}
                                  className={
                                      i === 0
                                          ? 'dropdown-item no-active'
                                          : 'dropdown-item'
                                  }
                                  key={`${value.text}-nav-key`}
                                  href={value.href}
                              >
                                  {value.text}
                              </a>
                          )
                      )
                    : localState.navLogin.map(value =>
                          value.type === 'link' ? (
                              <Link
                                  className="dropdown-item"
                                  key={`${value.text}-nav-key`}
                                  to={value.href}
                              >
                                  {' '}
                                  {translator.translate(value.text)}
                              </Link>
                          ) : (
                              <a
                                  className="dropdown-item"
                                  key={`${value.text}-nav-key`}
                                  href={value.href}
                              >
                                  {translator.translate(value.text)}
                              </a>
                          )
                      )}
            </div>
        </li>
    );
};

export default Login;
