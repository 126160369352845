import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
    getLocaleFromPath,
    getLocaleFromName,
    changedLocationURL
} from '@config/index';
import { DEVICE } from '@components/constants';
import User from './partials/User';
import Enterprise from './partials/Enterprise';

import './navigation.scss';

const hiLocale = getLocaleFromName('Hindi');
const enLocale = getLocaleFromName('English');

const Navigation = ({ status }) => {
    const location = useLocation();

    const { locale } = getLocaleFromPath(location.pathname);

    const device = useSelector(state => state.detect.device);

    return (
        <nav className="navbar navbar-expand-lg navbar-light" role="main">
            {device === DEVICE.MOBILE ? (
                <div className="language-block">
                    <Link
                        type="button"
                        className="btn dropdown-super-text language-btn"
                        style={{
                            backgroundColor:
                                locale === hiLocale ? '#2699fa' : ''
                        }}
                        to={changedLocationURL(location.pathname, hiLocale)}
                    >
                        हिन्दी<sup className="beta-launch-text">New</sup>
                    </Link>
                    <Link
                        type="button"
                        className="btn"
                        style={{
                            backgroundColor:
                                locale === enLocale ? '#2699fa' : ''
                        }}
                        to={changedLocationURL(location.pathname, enLocale)}
                    >
                        English
                    </Link>
                </div>
            ) : (
                ''
            )}

            <button
                className="navbar-toggler ml-auto collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span className="my-1 mx-2 close">X</span>
                <span className="navbar-toggler-icon" />
            </button>

            <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
            >
                {status === 2 ? <Enterprise /> : <User />}
                <div className="language-block hidden-xs">
                    <Link
                        type="button"
                        className="btn dropdown-super-text language-btn"
                        style={{
                            backgroundColor:
                                locale === hiLocale ? '#2699fa' : ''
                        }}
                        to={changedLocationURL(location.pathname, hiLocale)}
                    >
                        हिन्दी
                        <sup className="beta-launch-text obv-position-absolute">
                            New
                        </sup>
                    </Link>
                    <Link
                        type="button"
                        className="btn"
                        style={{
                            backgroundColor:
                                locale === enLocale ? '#2699fa' : ''
                        }}
                        to={changedLocationURL(location.pathname, enLocale)}
                    >
                        English
                    </Link>
                </div>
            </div>
        </nav>
    );
};

Navigation.propTypes = {
    status: PropTypes.number.isRequired
};

export default Navigation;
