import { setCookie } from '@components/elements/Cookie';
import {
    NAVIGATION_SHOW,
    NAVIGATION_HIDE,
    NAVIGATION_ENTERPRISE,
    UPDATE_LOCATION,
    GLOBAL_DATA
} from './HeaderActionTypes';

export function updateLocation(selectedLocation) {
    setCookie({ name: 'state_city', value: selectedLocation, time: 1 });

    if (document.getElementById('closeLocationModal')) {
        document.getElementById('closeLocationModal').click();
    }

    localStorage.setItem('state_city_home', selectedLocation);

    if (document.getElementById('usedCity')) {
        document.getElementById('usedCity').value = selectedLocation;
    }

    if (document.getElementById('etSelectedLocation')) {
        document.getElementById('etSelectedLocation').textContent =
            selectedLocation;
    }

    return {
        type: UPDATE_LOCATION,
        selectedLocation
    };
}
export function navigationShow() {
    return {
        type: NAVIGATION_SHOW
    };
}

export function navigationHide() {
    return {
        type: NAVIGATION_HIDE
    };
}

export function navigationEnterprise() {
    return {
        type: NAVIGATION_ENTERPRISE
    };
}

export function addGlobalData(data) {
    return {
        type: GLOBAL_DATA,
        globalData: data
    };
}
