import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Logo from '@components/Logo/Index';
import Navigation from '@components/Navigation/Index';
import { getCookie } from '@components/elements/Cookie';

import { PostData } from '@components/auth/fetchData';
import { setProfileData as SetCheckoutProfileData } from '@modules/checkout/CheckoutActions';
import ModalContainer from '@components/Modal/ModalContainer';
import * as HeaderActions from './HeaderActions';

import './header.scss';

const paths = {
    checkout: 'checkout',
    enterprise: 'enterprise',
    enterpriseLogin: 'login'
};

const locationComponent = /* #__LOADABLE__ */ () =>
    import('@modules/home/partials/modalLocation');

const HeaderComponent = () => {
    const dispatch = useDispatch();

    const navigation = useSelector(state => state.header.navigation);

    const ENV_WEB_URL = useSelector(state => state.initState.WEB_URL);

    const location = useLocation();

    React.useEffect(() => {
        const xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = thisObj => {
            if (thisObj.readyState === 4 && thisObj.status === 200) {
                const response = JSON.parse(thisObj.responseText);
                window.csrf_token = response.data;
                const meta = document.createElement('meta');
                meta.content = window.csrf_token;
                meta.name = 'csrf-token';
                document.getElementsByTagName('head')[0].appendChild(meta);
            }
        };
        xhttp.open('GET', `${ENV_WEB_URL}/get_token`, true);
        xhttp.send();
    }, [ENV_WEB_URL, location]);

    React.useEffect(() => {
        if (location.pathname.includes(paths.enterprise)) {
            if (location.pathname.includes(paths.enterpriseLogin)) {
                dispatch(HeaderActions.navigationHide());
            } else {
                dispatch(HeaderActions.navigationEnterprise());
            }
        } else if (location.pathname.includes(paths.checkout)) {
            dispatch(HeaderActions.navigationHide());
        } else {
            dispatch(HeaderActions.navigationShow());
        }
    }, [location.pathname, dispatch]);

    React.useEffect(() => {
        const cookieCity = getCookie('state_city');
        const localStorageCity = localStorage.getItem('state_city_home');

        if (cookieCity) {
            dispatch(HeaderActions.updateLocation(cookieCity));
        } else if (localStorageCity) {
            dispatch(HeaderActions.updateLocation(localStorageCity));
        }
    }, [dispatch]);

    React.useEffect(() => {
        PostData(`${ENV_WEB_URL}/check-user`, {
            obv_token: window?.__DATA__?.obv_token
        }).then(result => {
            const responseJson = result;

            if (responseJson.code === 'failed') {
                sessionStorage.removeItem('login_details');
            }

            const details = responseJson.data || {};

            if (responseJson.code === 'success') {
                sessionStorage.setItem(
                    'login_details',
                    JSON.stringify(details)
                );
            }

            dispatch(SetCheckoutProfileData(details));
        });
    }, [ENV_WEB_URL, dispatch, location.pathname]);

    return (
        <>
            <header className="main-layout-header mb-1 fixed" role="main">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-5 col-md-2">
                            <Logo />
                        </div>
                        <div className="col-7 col-md-10">
                            {navigation ? (
                                <Navigation status={navigation} />
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
            </header>
            <header className="main-layout-header mb-1" role="main">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-5 col-md-2">
                            <Logo />
                        </div>
                        <div className="col-7 col-md-10">
                            {navigation ? (
                                <Navigation status={navigation} />
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
            </header>
            <ModalContainer
                size="modal-lg"
                name="locationModal"
                heading="OBV Commercials"
                component={locationComponent}
            />
        </>
    );
};

export default HeaderComponent;
